import styled from 'styled-components';
import colors from '../../../assets/styles/colors';

export const Section = styled.section``

export const Title = styled.h2`
  word-break: break-word;
  font-size: 27px;
  color: white;
  margin: 0px;
  @media (min-width: 768px) {
    font-size: 48px;
    margin: 0px;
  }
`

export const SubTitle = styled.h3`
  word-break: break-word;
  font-size: 45px;
  color: white;
  margin: 0px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 70px;
    margin: -15px 0px 0px 0px;
  }
`

export const DescriptionA = styled.p`
  word-break: break-word;  
  font-size: 21px;
  color: white;
  margin: 0px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 40px;
    margin: 0px 0px 0px 0px;
  }
`

export const DescriptionAA = styled.p`
line-height: 21px;
  word-break: break-word;  
  font-size: 21px;
  color: white;
  margin: 0px 0px 0px 0px;
  @media (min-width: 768px) {
    line-height: 33px;
    font-size: 40px;
    margin: 0px 0px 0px 0px;
  }
`

export const DescriptionAAA = styled.p`
line-height: 21px;
  word-break: break-word;  
  font-size: 21px;
  color: white;
  margin: 0px 0px 0px 0px;
  @media (min-width: 768px) {
    line-height: 35px;
    font-size: 33px;
    margin: 0px 0px 0px 0px;
  }
`

export const DescriptionPrice = styled.p`
  word-break: break-word;  
  font-size: 29px;
  font-weight: 700;
  color: ${colors.yellow2};
  margin: 0px 0px 0px 5px;
  @media (min-width: 768px) {
    font-size: 50px;
    margin: 0px 0px 0px 10px;
  }
`

export const DescriptionB = styled.p`
  word-break: break-word;
  font-size: 16px;
  color: white;
  margin: 0px 0px 0px 0px;
  @media (min-width: 768px) {
    font-size: 21px;
    margin: 0px 0px 0px 0px;
  }
`

export const MainRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  background: ${colors.blue5};
  margin: 0px 0px 20px 0px;
`

export const Row = styled.div`
  width: 100%;
  flex: none;
`

export const RowPrice = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  /* justify-content: space-around; */
`

export const ColumnImg = styled.div`
  background: url(${props => props.background});
  background-size: 180%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 450px;
  display: flex;
  flex-flow: row wrap;
  padding: 20px;
  align-items: center;
  position: relative;
    @media (min-width: 768px) {
      background-size: 150%;
      width: 45%;
      height: 600px;
      padding: 0px;
    }
`

export const Column = styled.div`
  /* display: none; */
  width: 100%;
  @media (min-width: 768px) {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Container = styled.div`
  position: relative;
  border-radius: 50px;
  width: 70%;
  height: 80%;
  background: url(${props => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const ContainerTati = styled.div`
  position: relative;
  border-radius: 50px;
  width: 70%;
  height: 80%;
  background: url(${props => props.background});
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
`

export const Space = styled.div`
  margin-top: 15px;
`

export const NewAddress = styled.div`
  background-color: red;
  color: white;
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
  transform: rotate(-45deg);
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 200px;
  right: 20px;
  @media (min-width: 768px) {
    font-size: 20px;
    font-weight: 700;
    top: 270px;
    right: 20px;
  }
`