import React, { useEffect, useState } from 'react';

import { Flex } from '../../assets/styles/global';
import {
  MainRow,
  Row,
  Column,
  ColumnImg,
  Container,
  Title,
  SubTitle,
  DescriptionA,
  DescriptionB,
  RowPrice,
  DescriptionPrice,
  NewAddress
} from './lpBannerA.styles'

import UrlImagem from '../../assets/image/s-bg.png';
import UrlImagem2 from '../../assets/image/especialidadeExemplo1.webp';

import { CustomButton } from '../../components/CustomButton/customButton';
import { ContactWAForm } from '../../components/ContactForm/contactWAForm';
import { requestForm } from '../../helpers/requestForm';

export default function LpBannerA( { data, infoPrice } ) {

  const [ showContactForm, setShowContactForm ] = useState( false )

  const [ valueSubtitle, setValueSubtitle ] = useState()
  useEffect( () => {
    if ( infoPrice === undefined ) {
      setValueSubtitle( "70" )
    } else {
      setValueSubtitle( infoPrice )
    }
  }, [ infoPrice ] )



  const sendWhatsApp = () => {

    if ( data.data.slug === 'blumenau' ) {
      window.open( 'https://tintim.link/whatsapp/7598a614-f0c1-4df6-8b8c-cccf22a962e8/03565949-6a86-4dfd-8a9b-ad04e2a9dfbf', "_blank" );
      return
    }

    if ( data.data.slug !== 'mogidascruzes' ) {
      window.open( `https://api.whatsapp.com/send?phone=55${data.data.telefone_whats.replace( /[^\d]+/g, '' )}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Solumedi.`, "_blank" )
    }
  }

  const handleClick = ( params ) => {
    setShowContactForm( false )
    if ( !params?.accept ) return
    // SEND REQUEST
    requestForm( params.telefone_whats )
    sendWhatsApp()
  }

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
        <MainRow>
          <Column>
            <Container background={UrlImagem2}></Container>
          </Column>

          <ColumnImg background={UrlImagem}>
            <Row>
              <Title>{data.data.nomefantasia}</Title>
            </Row>
            <Row>
              {data.data.slug === 'blumenau' || data.data.slug === 'suzano' ?
                <>
                </>
                :
                <>
                  <Row>
                    <SubTitle>Consultas</SubTitle>
                  </Row>
                  <Row>
                    <SubTitle>&amp; Exames</SubTitle>
                  </Row>
                </>
              }
              <RowPrice>
                {data.data.slug === 'blumenau' || data.data.slug === 'suzano' ?
                  <DescriptionA>Consultas, Exames Laboratoriais e de Imagem com valores reduzidos e agendamento rápido!</DescriptionA>
                  :
                  <>
                    <DescriptionA>a partir de</DescriptionA>
                    <DescriptionPrice>R${valueSubtitle}</DescriptionPrice>
                  </>
                }

              </RowPrice>
              {data.data.slug === 'blumenau' && <NewAddress>ESTAMOS COM<br />NOVO WHATSAPP</NewAddress>}
            </Row>

            <Row>
              <Row>
                {data.data.slug === 'blumenau' || data.data.slug === 'suzano' ?
                  <DescriptionB>Atendimento particular com valores acessíveis. Sem mensalidade e sem taxa de adesão!</DescriptionB>
                  :
                  <DescriptionB>Atendemos mais de 30 especialidades com valores reduzidos!</DescriptionB>
                }
              </Row>
              <Row>
                <DescriptionB>Clique no botão abaixo e fale conosco pelo <strong>WhatsApp</strong> ou ligue <br></br><strong>{data.data.telefone_fixo}</strong></DescriptionB>
              </Row>
            </Row>

            <Row>
              <CustomButton
                onClick={() => sendWhatsApp()}
                color={"#2a4f72"}
                background={"#fdd460"}
                width={"60%"}>AGENDAR</CustomButton>
            </Row>

          </ColumnImg>
        </MainRow>

        {showContactForm ? <ContactWAForm handleClick={handleClick} /> : <></>}

      </Flex>
    </>
  );
}