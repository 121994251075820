import React, { useEffect, useState } from 'react';

import { Flex } from '../../assets/styles/global';
import {
  Title,
  Description,
} from './form.styles'

export default function Formulario() {

  const [ showThankYou, setShowThankYou ] = useState( false );

  const iframe = `<iframe id="iframe-grpro-integration" style="width: 100%;height: 700px;border: 0;" src="https://grpro.solumedi.com.br//crm/form.asp?id=1"></iframe><script type="text/javascript">window.addEventListener("message", (event) => {if(event.data.type == "HEIGHT") { document.getElementById("iframe-grpro-integration").height = event.data.value;}if(event.data.type == "URL") { document.getElementById('iframe-grpro-integration').contentWindow.postMessage({type: "URL-RECEIVE",url: window.location.href}, '*');}if(event.data.type == "URL-RETORNO") { window.location.href = event.data.value;}});</script>`
  const iframeTrack = `<iframe id="tracker-neg-grpro-v-0-0-1" class="d-none" style="display: none;" src="https://grpro.solumedi.com.br//traqueamento/"></iframe>`

  const IFrame = ( props ) => {
    return ( <div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} /> );
  }
  const IFrameTrack = ( props ) => {
    return ( <div dangerouslySetInnerHTML={{ __html: props.iframeTrack ? props.iframeTrack : "" }} /> );
  }

  const infoTag = `window.addEventListener("message", (event) => {let iframe_tracker = document.getElementById("tracker-neg-grpro-v-0-0-1");let iframe_tracker_form = document.getElementById("iframe-grpro-integration");let iframe_tracker_wpp = document.getElementById("i-bformw-grpro");if (event.data.type == "TRACKER-GRPRO-METRICA-URL"){type = "TRACKER-GRPRO-METRICA-URL-RET";url = window.location.href;cookie = document.cookie;iframe_tracker.contentWindow.postMessage({type, url, cookie}, '');}if (event.data.type == "TRACKER-GRPRO-METRICA-TOKEN-GENERATE"){document.cookie = 'GRPRO-TRACKER-NEG='+event.data.token+'; expires=' + event.data.data + '; path=/';}if (event.data.type == "TRACKER-GRPRO-METRICA-SEND-TOKEN"){type = event.data.type;token = event.data.token;if(iframe_tracker_form){setTimeout(function(){iframe_tracker_form.contentWindow.postMessage({type,token}, '');}, 1000);}if (iframe_tracker_wpp){setTimeout(function(){iframe_tracker_wpp.contentWindow.postMessage({type,token}, '*');}, 1000);}}});`

  useEffect( () => {
    setTimeout( () => {

      const GRPROscript = document.createElement( "script" );
      document.body.appendChild( GRPROscript );
      GRPROscript.type = "text/javascript"
      GRPROscript.innerHTML = infoTag;

      return () => {
        document.removeChild( GRPROscript );
      }
    }, 2000 );

    window.addEventListener( 'message', ( event ) => {
      if ( event.data.type === 'URL-RETORNO' ) {
        setShowThankYou( true );
      }
    } );

    return () => {
      window.removeEventListener( 'message', ( event ) => { /* cleanup */ } );
    };

  }, [] );

  return (
    <>
      <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginTop: "20px", marginBottom: "20px" }} >
        <Title>MAIS ATENDIMENTOS</Title>
        <Description>Aumente seu volume de atendimentos com a Solumedi</Description>
      </Flex>

      {showThankYou ? (
        <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
          <h2>Obrigado pelo seu envio!</h2>
          <p>Em breve, um de nossos atendentes entrará em contato.</p>
        </Flex>
      ) : (
        <Flex AlignItems={"center"} JustifyContent={"center"} FlexDirection={"column"} style={{ marginBottom: "40px" }} >
          <IFrame iframe={iframe} />
          <IFrameTrack iframe={iframeTrack} />
        </Flex>
      )}

    </>
  );
}
